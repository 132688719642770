/* Estilos generales para el contenedor */
#battletech-calculator {
    margin: 20px;
    font-family: Arial, sans-serif;
}

/* Estilo para el título */
.title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

/* Estilo para la barra de búsqueda */
.search-bar {
    margin-bottom: 20px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}
/* Estilos para los encabezados de la tabla */
.table-header {
    background-color: #4CAF50; /* Fondo verde */
    color: white; /* Texto blanco */
    font-weight: bold;
    text-align: center; /* Centrar el texto */
    padding: 12px; /* Espaciado adicional */
    border-bottom: 2px solid #000; /* Línea de separación inferior más gruesa */
}

/* Estilos para las filas de mechs y modelos */
.mech-row:hover {
    background-color: #f5f5f5;
    cursor: pointer;
}

/* Estilo para la fila seleccionada */
.selected-row {
    background-color: #d3d3d3;
    font-weight: bold;
}

/* Estilos para las celdas de mech y modelo */
.mech-name, .mech-model {
    padding: 10px;
    text-align: left;
}

/* Contenedor de la tabla y títulos */
.table-wrapper {
    align-items: center;
    margin: auto;
    margin-left: 0;
    margin-top: 0;
    display: none;
}
.table-wrapper.flex-active {
    display: flex;
}

/* Título de Piloting a la izquierda */
.title-piloting {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    font-weight: bold;
    margin-right: 20px;
    font-size: 20px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Título de Gunnery en la parte superior */
.title-gunnery {
    text-align: center;
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 20px;
}

/* Contenedor de la tabla */
.table-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Tabla de puntuaciones */
.score-table {
    width: 100%;
    border-collapse: collapse;
    margin: 0;
}

.score-table, .score-table td, .score-table th {
    border: 1px solid black;
}

/* Estilos para las celdas de puntuaciones */
.score-cell {
    padding: 10px;
    text-align: center;
    cursor: pointer;
}

/* Estilo para el hover de las celdas de puntaje */
.score-cell:hover {
    background-color: #e0e0e0;
}

/* Estilo para la celda de puntaje seleccionada */
.selected-score {
    background-color: #ffeb3b;
    font-weight: bold;
    color: #000;
}

/* Encabezado vacío */
.empty-header {
    width: 50px;
}

/* Niveles de Gunnery a la izquierda de cada fila */
.gunnery-level {
    background-color: #f2f2f2;
    text-align: center;
    font-weight: bold;
    padding: 10px;
}

/* Estilo para mostrar el puntaje seleccionado */
.selected-score-display {
    margin-top: 20px;
    font-size: 18px;
}

.search-bar-container{
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-left: 0;
}
.mech-table{
    margin: auto;
    margin-left: 0;
}
/* Botón de Importación */
#import-mech-button {
    background-color: #4CAF50; /* Verde */
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 10px 2px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

#import-mech-button:hover {
    background-color: #45a049;
}

/* Botón de Exportación */
#export-mech-button {
    background-color: #008CBA; /* Azul */
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 10px 2px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

#export-mech-button:hover {
    background-color: #007bb5;
}
.add-to-table-button {
    background-color: #ff9800; /* Naranja */
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 10px 2px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.add-to-table-button:hover {
    background-color: #e68900;
}

/* Tabla añadida */
.added-mechs-table {
    margin-top: 20px;
}

.mech-info-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
}

.mech-info-table th, .mech-info-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

