:root {
    --base-color: #0d0d0d;
    --secondary-color: #e47132;
    --tertiary-color: #141615;
    --complementary-color: #bbb;
  }
html, body {
  overscroll-behavior: none;
  font-family: "Rubik", sans-serif;
}
.Portfolio{
    min-height: 100vh;
    background-color: var(--base-color);
    display: flex;
    flex-direction: column;
    align-items: center;
}
.header{
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: var(--tertiary-color);
  border: 1px solid var(--secondary-color);
  border-top: none;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  color: var(--secondary-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}
.option{
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 10vw;
  min-height: 5vh;
  transition: 0.5s;
}
.option:hover{
  cursor: pointer;
  background-color: var(--secondary-color);
  color: var(--base-color);
}
.timeline {
    display: flex;
    align-items: center;
    position: relative;
    overscroll-behavior: contain;
    padding-top: 5%;
    overflow: hidden;
    width: 60vw;
}
  
.sphere {
    width: 400px;
    border: 10px solid var(--secondary-color);
    border-left: 0;
    height: 400px;
    border-radius: 50%;
    left: -200px;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.item{
  position: relative;
  height: 25px;
  width: 25px;
  background-color: var(--complementary-color);
  border-radius: 50%;
  display: flex;
  margin:auto;
  justify-content:center;
  align-items: center;
}
.selected{
  background-color: var(--secondary-color);
}
.date {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 0;
    font-size: 1.5rem;
    font-weight: bold;
    position: absolute;
    color: var(--secondary-color);
    left: 200px;
}
  
  .date span {
    margin: auto;
  }
  
.container-right {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--secondary-color);
    padding: 1%;
    border-radius: 10px;
    position: absolute;
    left: 220px;
    opacity: 1;
    transform: translateY(0);
    transition: none;
    color: var(--complementary-color);
    max-width: 40vw;
}
  
.new-container {
    opacity: 0;
}

  .new-date {
    opacity: 0;
  }
  
  @keyframes fadeOut {
    0% {
      opacity: 1;
      transform: rotate(0deg);
    }
    100% {
      opacity: 0;
      transform: rotate(90deg) translateX(370px) translateY(370px);
    }
  }
  
  @keyframes slideIn {
    0% {
      opacity: 0;
      transform: rotate(-90deg) translateX(370px) translateY(-370px);
    }
    100% {
      opacity: 1;
      transform: rotate(0deg) translateX(0) translateY(0);
    }
  }
  
  @keyframes fadeDateOut {
    0% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateY(100px);
    }
  }
  
  @keyframes fadeDateIn {
    0% {
      opacity: 0;
      transform: translateY(-100px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .fade-out {
    animation: fadeOut 1s forwards;
  }
  
  .slide-in {
    animation: slideIn 1s forwards;
  }
  
  .fade-date-out {
    animation: fadeDateOut 0.5s forwards;
  }
  
  .fade-date-in {
    animation: fadeDateIn 0.5s forwards;
  }
  
  .content {
    text-align: center;
  }
  .title {
    font-size: 1.7rem;
    font-weight: bold;
    color: var(--secondary-color);
  }
  .description {
    font-size: 1.4rem;
    color: var(--complementary-color);
  }
  .Head-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1%;
    margin-bottom: 1%;
  }
  .decoration {
    margin-right: 1%;
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 40px solid var(--secondary-color);
  }
  .proyects {
    display: flex;
    flex-direction: column;
    width: 60vw;
  }
  .proyect {
    display: flex;
    flex-direction: row;
    border-top: 1px solid var(--secondary-color);
    padding: 1%;
  }
  .proyect-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    max-width: 40vw;
  }
  .proyect-title {
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--secondary-color);
  }
  .proyect-img-container {
    width: 300px;
    height: 300px;
    border: 1px solid var(--secondary-color);
    border-radius: 10px;
    margin: 1%;
    overflow: hidden;
  }
  .proyect-img-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .primary {
    background-color: var(--tertiary-color);
    color: var(--complementary-color);
  }
  .secondary {
    background-color: var(--base-color);
    color: var(--complementary-color);
    flex-direction: row-reverse;
  }
  .line {
    height: 1px;
    background-color: var(--secondary-color);
  }

  .footer{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    align-items: center;
    background-color: var(--tertiary-color);
    min-height: 10vh;
    width: 100%;
  }
  .copy{
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--secondary-color);
  }
  .social{
    display: flex;
    justify-content: center;
    align-items: center;
    grid-column: 2;
  }
  .social a img{
    width: 30px;
    height: 30px;
    margin: 0 10px;
    filter: invert(52%) sepia(46%) saturate(931%) hue-rotate(336deg) brightness(94%) contrast(90%);
  }
  .proyect-description{
    text-align: justify;
  }
  .description strong{
    color: var(--secondary-color);
    font-weight: 400;
  }

  /* responsive */
  @media screen and (max-width: 768px){
    .timeline {
      overflow: hidden;
      width: 100%;
    }
    .container-right {
      max-width: 40vw;
    }
    .proyect {
      flex-direction: column;
      align-items: center;
    }
    .proyects{
      width: 100%;
    }
    .Head-title {
      margin-top: 3%;
      margin-bottom: 3%;
    }
    .proyect-info {
      max-width: 300px;
      margin-bottom: 3%;
    }
    .footer{
      grid-template-columns: 1fr 1fr;
    }
    .social{
      grid-column: 1;
    }
    .copy{
      font-size: 0.7rem;
    }
    .title {
      font-size: 1.2rem;
    }
    .description {
      font-size: 0.8rem;
    }
  }