.mantenimiento-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #141615;
    min-height: 100vh;
    margin: auto;

}
.img-container {
    display: flex;
    margin: 0;
    margin-bottom: 1vh;
    border: 10px solid #e47132;
    border-radius: 50%;
    overflow: hidden;
    width: 400px;
    height: 400px;
}

.typewriter-container{
    display: flex;
}
@keyframes typing {
    0% { width: 0; }
    50% { width: 100%; }
    60% { width: 100%; }
    100% { width: 0; }
  }
  
  .typewriter h1 {
    display: flex;
    overflow: hidden;
    border-right: .15em solid #e47132; /* Cursor */
    white-space: nowrap;
    color: #e47132;
    margin: 0 auto;
    margin-left: 0;
    font-size: 2em;
    font-family: 'Roboto';
    font-weight: 400;
    animation:
      typing 6s steps(40, end) infinite,
      blink-caret .75s step-end infinite;
  }
  
  @keyframes blink-caret {
    from, to { border-color: transparent; }
    50% { border-color: #e47132; }
  }

/* responsive */
@media screen and (max-width: 768px){
    .img-container {
        width: 300px;
        height: 300px;
        margin-bottom: 3vh;
    }
    .typewriter h1 {
        font-size: 1.2em;
    }
}
  