.calculadora-container {
    background-color: #333;
    color: #c0c0c0;
    border: 2px solid #666;
    border-radius: 8px;
    padding: 20px;
    max-width: 600px;
    margin: 20px auto;
    font-family: 'Gothic', sans-serif;
  }
  
  .calculadora-heading {
    color: #f8d347; /* Gold color for headings */
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.5em;
  }
  
  .calculadora-label {
    display: block;
    margin: 10px 0;
  }
  
  .calculadora-input {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #555;
    border-radius: 4px;
    background-color: #222;
    color: #ddd;
  }
  
  .calculadora-checkbox {
    margin-left: 10px;
  }
  
  .calculadora-button {
    display: block;
    width: 100%;
    padding: 10px;
    margin-top: 20px;
    background-color: #444;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
  }
  
  .calculadora-button:hover {
    background-color: #555;
  }
  
  .calculadora-resultado {
    margin-top: 15px;
    padding: 10px;
    background-color: #222;
    border-radius: 4px;
    text-align: center;
  }
  